import {TableBody, TableContainer, TableHead, TableRow} from "@material-ui/core";
import React from "react";
import {answerAnalysisMap, AnswerDetail, answerStats, AnswerStats} from "../../../app/quizCheatCheckingSlice";
import {StyledTable, StyledTableCell} from "../../../sharedStyles/sharedStyledComponents";
import {useAppSelector} from "../../../app/hooks";
import {
    activeCaseMetaData,
    CaseMetaDataStudentInfo,
    navbarActiveCaseType
} from "../../../app/navbarQuizCheatCheckingSlice";

const AnswerAnalysisRemarkTableCell = (caseType: string, answerDetails: Record<string, unknown>, studentIDs: [], answerStats: AnswerStats) => {
    
    // console.log(`[AnswerAnalysisRemarkTableCell]: ${studentIDs}`)
    
    
    switch (caseType) {
        case "time_outlier": {
            return (
                <StyledTableCell>
                    /
                </StyledTableCell>
            )
            break;
        }
            
        case "pageview": {
            if (studentIDs.length !== 2) {
                return (
                    <StyledTableCell>
                        Data Missing
                    </StyledTableCell>
                );
            }
            const answerDetail1 = answerDetails[studentIDs[0]];
            const answerDetail2 = answerDetails[studentIDs[1]];
            console.log(`[AnswerAnalysisRemarkTableCell with Props]: ${JSON.stringify(answerDetail1)}`)
            if (answerDetail1 === undefined || answerDetail2 === undefined) {
                return (
                    <StyledTableCell>
                        Different Question
                    </StyledTableCell>
                );
            } else if (answerDetail1.answer === answerDetail2.answer) {
                if (answerStats === undefined) {
                    return (
                        <StyledTableCell backgroundColor="rgba(252,212,53,0.7)">
                            Same Question, Same Answers
                        </StyledTableCell>
                    )
                }

                if (answerDetail1.correctness === "TRUE" && answerDetail2.correctness === "TRUE" && answerStats.correctPercent <= 80) {
                    return (
                        <StyledTableCell backgroundColor="rgba(255,0,0,0.70)">
                            Identical Correct Answers, Low Correct Rate
                        </StyledTableCell>
                    );
                } else if (answerDetail1.correctness === "FALSE" && answerDetail2.correctness === "FALSE" && answerStats.correctPercent >= 80) {
                    return (
                        <StyledTableCell backgroundColor="rgba(255,0,0,0.70)">
                            Identical Wrong Answer for High Correct Rate Question
                        </StyledTableCell>
                    );
                } else {
                    return (
                        <StyledTableCell  backgroundColor="rgba(252,212,53,0.7)">
                            Same Question, Same Answers
                        </StyledTableCell>
                    );
                }

            } else {
                return (
                    <StyledTableCell>
                        Same Question, Different Answers
                    </StyledTableCell>
                );
            }
            break;
        }
            
    }
}
const AnswerTableCell = (answerDetail: AnswerDetail, subquestionType: string) => {
    let answerContent = "-";
    let fontColor = undefined;
    if (answerDetail !== undefined) {
        if (subquestionType === "essay") {
            answerContent = "Essay";
        } else {
            answerContent = answerDetail.answer;
        }
        if (answerDetail.correctness.toUpperCase() === "TRUE") {
            fontColor = "rgba(52,175,67,0.7)";
        }
        if (answerDetail.correctness.toUpperCase() === "FALSE") {
            fontColor = "rgba(255,0,0,0.70)";
        }
    }

    return (
        <StyledTableCell maxWidth='6vw' fontColor={fontColor}>{answerContent}</StyledTableCell>
    );
}

export const AnswerTableWithProps = ( props: {studentAnswerAnalyisMap: any, studentIDs: any, quizAnswerStatus : any}) => {
    
    console.log(`[AnswerTable Props] : ${JSON.stringify(props.studentAnswerAnalyisMap)}, ${JSON.stringify(props.studentIDs)}, ${JSON.stringify(props.quizAnswerStatus)}`)
    
    
    const caseType = "pageview"
    const studentIDs = props.studentIDs
    const quizAnswerStatus = props.quizAnswerStatus
    const answerAnalysisMap = props.studentAnswerAnalyisMap


    const sortedAnswerAnalysisKeys = Object.keys(answerAnalysisMap);
    sortedAnswerAnalysisKeys.sort((a, b) => a.localeCompare(b));

    return (
        <TableContainer style={{
            maxHeight: '100%',
            overflow: 'auto',
            padding: '1rem 1rem 1rem 1rem',
            width: '96%'
        }}>
            <StyledTable>
                <TableHead>
                    <TableRow>
                        <StyledTableCell isHeader={true}>Pool ID</StyledTableCell>
                        <StyledTableCell isHeader={true}>Subquestion ID</StyledTableCell>
                        {studentIDs.map((studentID) =>
                            <StyledTableCell isHeader={true} key={'AnswerTableHead_' + studentID}><div>Answer</div><div>({studentID})</div></StyledTableCell>
                        )}
                        <StyledTableCell isHeader={true}>Remarks</StyledTableCell>
                        <StyledTableCell isHeader={true}>Question Type</StyledTableCell>
                        <StyledTableCell isHeader={true}>Correct Percent</StyledTableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedAnswerAnalysisKeys.map((questionPoolSlug) => (
                        Object.keys(answerAnalysisMap[questionPoolSlug]).sort((a, b) => a.localeCompare(b)).map((subquestionSlug, index) => {
                            const subquestionType = answerAnalysisMap[questionPoolSlug][subquestionSlug].subquestionType;
                            const subquestionLength = Object.keys(answerAnalysisMap[questionPoolSlug]).length;
                            return (
                                <TableRow key={subquestionSlug}>
                                    {subquestionLength === 1 ? <StyledTableCell>{questionPoolSlug}</StyledTableCell> : undefined}
                                    {subquestionLength !== 1 && index === 0 ? <StyledTableCell borderBottom='none'>{questionPoolSlug}</StyledTableCell> : undefined}
                                    {subquestionLength !== 1 && index !== 0 && index !== subquestionLength - 1 ? <StyledTableCell borderBottom='none'> </StyledTableCell> : undefined}
                                    {subquestionLength !== 1 && index === subquestionLength - 1 ? <StyledTableCell> </StyledTableCell> : undefined}
                                    {/*{subquestionLength === 1 ? <StyledTableCell>{questionPoolSlug}</StyledTableCell> : index === 0 ? <StyledTableCell borderBottom='none'>{questionPoolSlug}</StyledTableCell> : <StyledTableCell> </StyledTableCell>}*/}
                                    <StyledTableCell>{subquestionSlug}</StyledTableCell>
                                    {studentIDs.map((studentID: any) => {
                                        if (answerAnalysisMap[questionPoolSlug][subquestionSlug].subquestionType !== "essay") {
                                            return AnswerTableCell(answerAnalysisMap[questionPoolSlug][subquestionSlug].answerDetails[studentID], subquestionType);
                                        } else {
                                            return (
                                                <StyledTableCell maxWidth='10vw' key={'AnswerTableRow_' + subquestionSlug + '_' + studentID}>Essay</StyledTableCell>
                                            )
                                        }}
                                    )}
                                    {AnswerAnalysisRemarkTableCell(caseType, answerAnalysisMap[questionPoolSlug][subquestionSlug].answerDetails, studentIDs, quizAnswerStatus[subquestionSlug])}
                                    <StyledTableCell>{answerAnalysisMap[questionPoolSlug][subquestionSlug].subquestionType}</StyledTableCell>
                                    {quizAnswerStatus[subquestionSlug]
                                        ? <StyledTableCell>{quizAnswerStatus[subquestionSlug].correctNumber}/{quizAnswerStatus[subquestionSlug].attemptsNumber} ({quizAnswerStatus[subquestionSlug].correctPercent}%)</StyledTableCell>
                                        : <StyledTableCell> </StyledTableCell>
                                    }
                                </TableRow>
                            )
                        })
                    ))}
                </TableBody>
            </StyledTable>
        </TableContainer>
    )
}

