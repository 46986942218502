import {useEffect} from "react";
import Plot from "react-plotly.js";
import {useAppSelector} from "../../../app/hooks";
import {navbarActiveCaseType} from "../../../app/navbarQuizCheatCheckingSlice";

export const PageViewGraph = (props: { data: any, length: any }) => {
    const navbarActiveCaseTypeRedux = useAppSelector(navbarActiveCaseType);
    let data = props.data;

    if (data[0].length === 4) {
        data = data[0];
    }

    const getOverlappingTimeRange = (data1: any, data2: any) => {
        const data1StartTime = new Date(Math.min(...data1.x.map((time: any) => new Date(time).getTime())));
        const data1EndTime = new Date(Math.max(...data1.x.map((time: any) => new Date(time).getTime())));

        const data2StartTime = new Date(Math.min(...data2.x.map((time: any) => new Date(time).getTime())));
        const data2EndTime = new Date(Math.max(...data2.x.map((time: any) => new Date(time).getTime())));

        const overlapStartTime = new Date(Math.max(data1StartTime.getTime(), data2StartTime.getTime()));
        const overlapEndTime = new Date(Math.min(data1EndTime.getTime(), data2EndTime.getTime()));

        return { overlapStartTime, overlapEndTime };
    };

    const limitDataToTimeRange = (data: any, startTime: Date, endTime: Date) => {
        const filteredX = data.x.filter((time: any) => {
            const eventTime = new Date(time);
            return eventTime >= startTime && eventTime <= endTime;
        });

        const filteredY = data.y.slice(data.x.indexOf(filteredX[0]), data.x.indexOf(filteredX[filteredX.length - 1]) + 1);

        return {
            ...data,
            x: filteredX,
            y: filteredY,
        };
    };

    const rawData = data;
    let filteredData = [];

    if (data.length === 4) {
        const data1 = data[0];
        const data2 = data[1];
        const data3 = data[2];
        const data4 = data[3];

        const { overlapStartTime, overlapEndTime } = getOverlappingTimeRange(data1, data2);

        filteredData = [
            limitDataToTimeRange(data1, overlapStartTime, overlapEndTime),
            limitDataToTimeRange(data2, overlapStartTime, overlapEndTime),
            limitDataToTimeRange(data3, overlapStartTime, overlapEndTime),
            limitDataToTimeRange(data4, overlapStartTime, overlapEndTime)
        ];
    }

    const plotData = [
        {
            ...rawData[0],
        },
        {
            ...rawData[1],
        },
        {
            ...rawData[2],
        },
        {
            ...rawData[3],
        },
        {
            ...filteredData[0],
            name: filteredData[0].name + " (Filtered)",
            visible: 'legendonly',
        },
        {
            ...filteredData[1],
            name: filteredData[1].name + " (Filtered)",
            visible: 'legendonly',
        },
        {
            ...filteredData[2],
            name: filteredData[2].name + " (Filtered)",
        },
        {
            ...filteredData[3],
            name: filteredData[3].name + " (Filtered)",
        },
    ];

    return (
        <Plot
            data={plotData}
            style={{ width: "95%", height: navbarActiveCaseTypeRedux.value === 'pageview' ? "65%" : "90%" }}
            useResizeHandler
            layout={{
                font: {
                    family: "Fira Sans, sans-serif",
                    size: 12,
                },
                autosize: true,
                margin: { l: 80, r: 0, b: 80, t: 0 },
                xaxis: {
                    title: "Timestamp",
                },
                yaxis: {
                    title: {
                        text: "Page Number",
                    }
                },
                legend: {
                    x: 0.2,
                    y: -0.15,
                    orientation: "h"
                }
            }}
        />
    );
}