import { AppThunk, RootState } from './store';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

// for this slice, course slug and quiz slug is already given
export interface QuizCheatCheckingState {
  // question's subquestion_slug -> AnswerStats
  answerStats: Record<string, unknown>;
  answerStatsStatus: boolean;
  answerStatsError: string;

  // questionPoolSlug -> {subquestionSlug -> AnswerAnalysis}
  answerAnalysisMap: Record<string, unknown>;
  answerAnalysisMapStatus: boolean;
  answerAnalysisMapError: string;

  pageViewAnalysis: PageViewAnalysis;
  pageViewAnalysisStatus: boolean;
  pageViewAnalysisError: string;

  // studentID -> StudentInfo
  studentInfo: Record<string, unknown>;
  studentInfoStatus: boolean;
  studentInfoError: string;

  // selectedStudents
  selectedStudents: Student[];
}

export interface AnswerStats {
  questionPoolSlug: string;
  subquestionSlug: string;
  attemptsNumber: number;
  correctNumber: number;
  correctPercent: number;
}

export interface AnswerDetail {
  answer: string;
  correctness: string;
}

interface AnswerAnalysis {
  questionPoolSlug: string;
  subquestionSlug: string;
  subquestionType: string;
  // studentSlug -> AnswerDetail
  answerDetails: Record<string, unknown>;
}

export interface PageViewDetail {
  time: string;
  pageNum: string;
}

export interface SubquestionAttemptDetail {
  time: string;
  pageNum: string;
}

export interface PageViewAnalysis {
  overlapPageNumber: number;
  syncPageNumber: number;
  overlapTimeDuration: number;
  overlapPercentage: number;
  // studentSlug -> PageViewDetail[]
  pageViewDetails: Record<string, unknown>;
  // studentSlug -> SubQuestionAttemptDetail[]
  subquestionAttemptDetails: Record<string, unknown>;
}

interface StudentInfo {
  // from c4 db
  firstName: string;
  lastName: string;
  middleInitial: string;
  email: string;
  college: string;
  department: string;
  campus: string;
  semester: string;
  course: string;
  section: string;
  dropped: boolean;
  // awsEmail: string;
  // awsAccountID: string;
  // azureEmail: string;
  // azureSubscription: string;
  // azureCoupon: string;
  team: string;
  teamEmail: string;
  // from data warehouse person info
  id: string;
  role: string;
  status: string;
  usedGraceDayNumber: string;
  // from data warehouse quiz events
  quizTimeDuration: string;
  quizScore: string;
}

export interface Student {
  label: string;
  value: string;
}

const initialState: QuizCheatCheckingState = {
  answerStatsStatus: false,
  answerStatsError: '',
  answerStats: {},
  answerAnalysisMapStatus: false,
  answerAnalysisMapError: '',
  answerAnalysisMap: {},
  pageViewAnalysisStatus: false,
  pageViewAnalysisError: '',
  pageViewAnalysis: {
    overlapPageNumber: -1,
    syncPageNumber: -1,
    overlapTimeDuration: -1,
    overlapPercentage: -1,
    pageViewDetails: {},
    subquestionAttemptDetails: {},
  },
  studentInfoStatus: false,
  studentInfoError: '',
  studentInfo: {},
  selectedStudents: [],
};

const { REACT_APP_BACKEND_URI } = process.env;

function getStorageValue(key: string, defaultValue: string) {
  // getting stored value
  if (typeof window !== 'undefined') {
    const saved = localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  }
}

export const quizCheatCheckingSlice = createSlice({
  name: 'quizCheatChecking',
  initialState,
  reducers: {
    updateAnswerStats: (state, action: PayloadAction<any>) => {
      action.payload.forEach((item: any) => {
        item.question_pool_slug = 'question_slug';
        state.answerStats[item.question_slug] = {
          questionPoolSlug: 'question_slug',
          subquestionSlug: item.question_slug,
          attemptsNumber: item.total_attempts,
          correctNumber: item.num_correct,
          correctPercent: item.percent_correct,
        };
      });
      state.answerStatsStatus = true;
      state.answerStatsError = initialState.answerStatsError;
    },
    cleanAnswerStats: (state) => {
      state.answerStats = initialState.answerStats;
      state.answerStatsStatus = initialState.answerStatsStatus;
      state.answerStatsError = initialState.answerStatsError;
    },
    updateAnswerStatsError: (state, action: PayloadAction<any>) => {
      state.answerStats = initialState.answerStats;
      state.answerStatsStatus = false;
      state.answerStatsError = action.payload.message;
    },
    cleanAnswerAnalysisMap: (state) => {
      state.answerAnalysisMapStatus = initialState.answerAnalysisMapStatus;
      state.answerAnalysisMap = initialState.answerAnalysisMap;
      state.answerAnalysisMapError = initialState.answerAnalysisMapError;
    },
    updateAnswerAnalysisMap: (state, action: PayloadAction<any>) => {
      const response = action.payload.response;
      const studentID = action.payload.studentID;
      for (const idx in response) {
        response[idx].question_pool_slug = 'Not available';
        if (
          state.answerAnalysisMap[response[idx].question_pool_slug] ===
          undefined
        ) {
          state.answerAnalysisMap[response[idx].question_pool_slug] = {};
        }
        if (
          state.answerAnalysisMap[response[idx].question_pool_slug][
            response[idx].question_slug
          ] === undefined
        ) {
          state.answerAnalysisMap[response[idx].question_pool_slug][
            response[idx].question_slug
          ] = {
            questionPoolSlug: response[idx].question_pool_slug,
            subquestionSlug: response[idx].question_slug,
            subquestionType: response[idx].question_type,
            // studentSlug -> AnswerDetail
            answerDetails: {},
          };
        }
        state.answerAnalysisMap[response[idx].question_pool_slug][
          response[idx].question_slug
        ].answerDetails[studentID] = {
          answer: response[idx].answer,
          correctness: response[idx].correctness,
          type: response[idx].question_type,
        };
      }
    },
    updateAnswerAnalysisMapStatus: (state, action: PayloadAction<any>) => {
      state.answerAnalysisMapStatus = action.payload;
    },
    updateAnswerAnalysisMapError: (state, action: PayloadAction<any>) => {
      state.answerAnalysisMapError = action.payload.message;
    },
    cleanPageViewAnalysis: (state) => {
      state.pageViewAnalysisStatus = initialState.pageViewAnalysisStatus;
      state.pageViewAnalysis = initialState.pageViewAnalysis;
      state.pageViewAnalysisError = initialState.pageViewAnalysisError;
    },
    updatePageViewAnalysis: (state, action: PayloadAction<any>) => {
      if (action.payload.length !== 1) {
        console.log('No matched page view analysis data');
      } else {
        state.pageViewAnalysis.overlapPageNumber =
          action.payload[0].num_overlapping_pages;
        state.pageViewAnalysis.syncPageNumber =
          action.payload[0].num_sync_pageviews;
        state.pageViewAnalysis.overlapTimeDuration =
          action.payload[0].overlap_secs;
        state.pageViewAnalysis.overlapPercentage =
          action.payload[0].overlap_percentage;
      }
    },
    updatePageViewDetails: (state, action: PayloadAction<any>) => {
      if (
        action.payload.details === undefined ||
        action.payload.details.length === 0
      ) {
        console.log(
          'No page view data founded for ' + action.payload.studentID,
        );
      }
      const pageViewDetails: PageViewDetail[] = [];
      action.payload.details.forEach((detail: any) => {
        pageViewDetails.push({
          time: detail.event_time,
          pageNum: detail.sail_quiz_page,
        });
      });

      state.pageViewAnalysis.pageViewDetails[action.payload.studentID] =
        pageViewDetails;
    },
    updateSubquestionAttemptDetails: (state, action: PayloadAction<any>) => {
      if (
        action.payload.details === undefined ||
        action.payload.details.length === 0
      ) {
        console.log(
          'No subquestion attempt data founded for ' + action.payload.studentID,
        );
      }
      const subquestionAttemptDetails: SubquestionAttemptDetail[] = [];
      action.payload.details.forEach((detail: any) => {
        subquestionAttemptDetails.push({
          time: detail.event_time,
          pageNum: detail.sail_quiz_page,
        });
      });
      state.pageViewAnalysis.subquestionAttemptDetails[
        action.payload.studentID
      ] = subquestionAttemptDetails;
    },
    updatePageViewAnalysisStatus: (state, action: PayloadAction<any>) => {
      state.pageViewAnalysisStatus = action.payload;
    },
    updatePageViewAnalysisError: (state, action: PayloadAction<any>) => {
      state.pageViewAnalysisStatus = false;
      state.pageViewAnalysis = initialState.pageViewAnalysis;
      state.pageViewAnalysisError = action.payload.message;
    },
    cleanStudentInfo: (state) => {
      state.studentInfoStatus = initialState.studentInfoStatus;
      state.studentInfo = initialState.studentInfo;
      state.studentInfoError = initialState.studentInfoError;
    },
    updateStudentBasicInfo: (state, action: PayloadAction<any>) => {
      console.log('[Function called] updateStudentBasicInfo');
      if (action.payload.response.length === 0) {
        console.log(
          "No matched student's basic info for " + action.payload.studentID,
        );
      } else if (action.payload.response.length !== 1) {
        console.log(
          "Multiple matched student's basic info for " +
            action.payload.studentID,
        );
      } else {
        state.studentInfo[action.payload.response[0].person_id] = {
          ...state.studentInfo[action.payload.response[0].person_id],
          role: action.payload.response[0].person_roles,
          status: action.payload.response[0].person_status,
          usedGraceDayNumber: action.payload.response[0].person_grace_days_used,
        };
      }
      console.log(
        `[updateStudentBasicInfo]: ${JSON.stringify(state.studentInfo)}`,
      );
    },
    updateStudentC4BasicInfo: (state, action: PayloadAction<any>) => {
      if (action.payload.response.length === 0) {
        console.log(
          "No matched student's c4 basic info for " + action.payload.studentID,
        );
      } else if (action.payload.response.length !== 1) {
        console.log(
          "Multiple matched student's c4 basic info for " +
            action.payload.studentID,
        );
      } else {
        state.studentInfo[action.payload.response[0].person_id] = {
          ...state.studentInfo[action.payload.response[0].person_id],
          firstName: action.payload.response[0].first_name,
          lastName: action.payload.response[0].last_name,
          middleInitial: action.payload.response[0].middle_initial,
          email: action.payload.response[0].email,
          college: action.payload.response[0].college,
          department: action.payload.response[0].department,
          campus: action.payload.response[0].campus,
          semester: action.payload.response[0].semester,
          course: action.payload.response[0].course,
          section: action.payload.response[0].section,
          dropped: action.payload.response[0].dropped,
          team: action.payload.response[0].team_id,
          teamEmail: action.payload.response[0].team_email,
        };
      }
    },
    updateStudentQuizStats: (state, action: PayloadAction<any>) => {
      console.log('student quiz score: aaa', action.payload.response[0]);
      console.log('[Function called] updateStudentQuizStats');
      // may have multi-attempts, but only deal with single right now
      if (action.payload.response.length === 0) {
        console.log(
          "No matched student's quiz stats for " + action.payload.studentID,
        );
      } else if (action.payload.response.length !== 1) {
        let quizDuration = '';
        let quizScore = '';
        for (const idx in action.payload.response) {
          quizDuration +=
            action.payload.response[idx].time_duration_minutes + ', ';
          quizScore += action.payload.response[idx].score_obtained + ', ';
        }
        state.studentInfo[action.payload.response[0].person_id] = {
          ...state.studentInfo[action.payload.response[0].person_id],
          quizTimeDuration: quizDuration.substring(0, quizDuration.length - 2),
          quizScore: quizScore.substring(0, quizScore.length - 2),
        };
        console.log(
          "Multiple matched student's quiz stats for " +
            action.payload.studentID,
        );
      } else {
        state.studentInfo[action.payload.response[0].person_id] = {
          ...state.studentInfo[action.payload.response[0].person_id],
          quizTimeDuration: action.payload.response[0].time_duration_minutes,
          quizScore: action.payload.response[0].score_obtained,
        };
      }
      console.log(
        `[updateStudentQuizStats]: ${JSON.stringify(state.studentInfo)}`,
      );
    },
    updateStudentInfoStatus: (state, action: PayloadAction<any>) => {
      state.studentInfoStatus = action.payload;
    },
    updateStudentInfoError: (state, action: PayloadAction<any>) => {
      state.studentInfo = initialState.studentInfo;
      updateStudentInfoStatus(false);
      state.studentInfoError = action.payload;
    },
    updateSelectedStudents: (state, action: PayloadAction<Student[]>) => {
      state.selectedStudents = action.payload;
    },
  },
});

export const getAnswerStats =
  (courseSlug: string, quizSlug: string): AppThunk =>
  (dispatch) => {
    dispatch(cleanAnswerStats());
    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);
    fetch(
      `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/stats/`,
      { method: 'GET', headers: headers },
    )
      .then((promise: any) => {
        return promise.json();
      })
      .then((response: any) => {
        dispatch(updateAnswerStats(response));
      })
      .catch((error: any) => {
        dispatch(updateAnswerStatsError(error));
      });
  };

export const getAnswerAnalysis =
  (courseSlug: string, quizSlug: string, studentIDs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(cleanAnswerAnalysisMap());
    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);
    const promises = [];
    for (const idx in studentIDs) {
      promises.push(
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentIDs[idx]}/answers/`,
          { method: 'GET', headers: headers },
        ),
      );
    }

    Promise.all(promises)
      .then((responses) => {
        return Promise.all(
          responses.map(async function (response) {
            if (response.ok) return response.json();
            else {
              const errorText = await response.text();
              throw new Error(`${errorText} for ${response.url}`);
            }
          }),
        );
      })
      .then((responses) => {
        responses.forEach((response, index) => {
          dispatch(
            updateAnswerAnalysisMap({
              response: response,
              studentID: studentIDs[index],
            }),
          );
        });
      })
      .then(() => {
        dispatch(updateAnswerAnalysisMapStatus(true));
      })
      .catch((error) => {
        dispatch(updateAnswerAnalysisMapError(error));
      });
  };

export const getPageViewAnalysis =
  (
    courseSlug: string,
    quizSlug: string,
    caseType: string,
    studentIDs: string[],
  ): AppThunk =>
  async (dispatch) => {
    dispatch(cleanPageViewAnalysis());
    console.log('Start: Page view analysis API');
    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);
    const promises = [];
    switch (caseType) {
      case 'pageview':
        promises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentIDs[0]}/pageviews/`,
            { method: 'GET', headers: headers },
          ),
        );
        promises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentIDs[1]}/pageviews/`,
            { method: 'GET', headers: headers },
          ),
        );
        promises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/pageview/${studentIDs[0]}/${studentIDs[1]}/`,
            { method: 'GET', headers: headers },
          ),
        );
        promises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentIDs[0]}/attempts/`,
            { method: 'GET', headers: headers },
          ),
        );
        promises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentIDs[1]}/attempts/`,
            { method: 'GET', headers: headers },
          ),
        );
        break;
      case 'time_outlier':
        promises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentIDs[0]}/pageviews/`,
            { method: 'GET', headers: headers },
          ),
        );
        promises.push(
          fetch(
            `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentIDs[0]}/attempts/`,
            { method: 'GET', headers: headers },
          ),
        );
        break;
      default:
        break;
    }

    Promise.all(promises)
      .then((responses) => {
        console.log('Before return Page view analysis API');
        return Promise.all(
          responses.map(async function (response) {
            if (response.ok) return response.json();
            else {
              const errorText = await response.text();
              throw new Error(`${errorText} for ${response.url}`);
            }
          }),
        );
      })
      .then((responses) => {
        switch (caseType) {
          case 'pageview':
            responses.forEach((response, index) => {
              // console.log(
              //   `response: ${JSON.stringify(response)}, index: ${JSON.stringify(index)}`,
              // );
              // console.log(`Index: ${index}`);
              if (index < 2) {
                dispatch(
                  updatePageViewDetails({
                    details: response,
                    studentID: studentIDs[index],
                  }),
                );
              } else if (index === 2) {
                dispatch(updatePageViewAnalysis(response));
              } else {
                dispatch(
                  updateSubquestionAttemptDetails({
                    details: response,
                    studentID: studentIDs[index - 3],
                  }),
                );
              }
            });
            break;
          case 'time_outlier':
            responses.forEach((response, index) => {
              if (index === 0) {
                dispatch(
                  updatePageViewDetails({
                    details: response,
                    studentID: studentIDs[0],
                  }),
                );
              } else {
                dispatch(
                  updateSubquestionAttemptDetails({
                    details: response,
                    studentID: studentIDs[0],
                  }),
                );
              }
            });
            break;
          default:
            break;
        }
      })
      .then(() => {
        dispatch(updatePageViewAnalysisStatus(true));
      })
      .catch((error) => {
        dispatch(updatePageViewAnalysisError(error));
      });
  };

export const getStudentInfo =
  (courseSlug: string, quizSlug: string, studentIDs: string[]): AppThunk =>
  async (dispatch) => {
    dispatch(cleanStudentInfo());
    const cookieValue = getStorageValue('jwt', 'baljit');
    const headers = new Headers();
    headers.append('x-access-token', cookieValue);

    const promises = [];

    for (const idx in studentIDs) {
      promises.push(
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseSlug}/meta/student/${studentIDs[idx]}/`,
          { method: 'GET', headers: headers },
        ),
      );
      promises.push(
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseSlug}/meta/student/${studentIDs[idx]}/student_info/`,
          { method: 'GET', headers: headers },
        ),
      );
      promises.push(
        fetch(
          `${REACT_APP_BACKEND_URI}/api/${courseSlug}/cheatchecking/quiz/${quizSlug}/student/${studentIDs[idx]}/stats/`,
          { method: 'GET', headers: headers },
        ),
      );
    }
    console.log('line 605');
    Promise.all(promises)
      .then((responses) => {
        return Promise.all(
          responses.map(async function (response) {
            if (response.ok) return response.json();
            else {
              const errorText = await response.text();
              throw new Error(`${errorText} for ${response.url}`);
            }
          }),
        );
      })
      .then((responses) => {
        responses.forEach((response, index) => {
          switch (index % 3) {
            case 0:
              console.log('[get StudentInfo] case0');
              dispatch(
                updateStudentBasicInfo({
                  response: response,
                  studentID: studentIDs[Math.floor(index / 3)],
                }),
              );
              break;
            case 1:
              dispatch(
                updateStudentC4BasicInfo({
                  response: response,
                  studentID: studentIDs[Math.floor(index / 3)],
                }),
              );
              break;
            case 2:
              console.log('[get StudentInfo] case1');
              dispatch(
                updateStudentQuizStats({
                  response: response,
                  studentID: studentIDs[Math.floor(index / 3)],
                }),
              );
              break;
          }
        });
      })
      .then(() => {
        // finally set status to true
        dispatch(updateStudentInfoStatus(true));
      })
      .catch((error) => {
        dispatch(updateStudentInfoError(error.message));
      });
  };

export const answerStatsStatus = (state: RootState) =>
  state.quizCheatChecking.answerStatsStatus;
export const answerStatsError = (state: RootState) =>
  state.quizCheatChecking.answerStatsError;
export const answerStats = (state: RootState) =>
  state.quizCheatChecking.answerStats;
export const answerAnalysisMapStatus = (state: RootState) =>
  state.quizCheatChecking.answerAnalysisMapStatus;
export const answerAnalysisMapError = (state: RootState) =>
  state.quizCheatChecking.answerAnalysisMapError;
export const answerAnalysisMap = (state: RootState) =>
  state.quizCheatChecking.answerAnalysisMap;
export const pageViewAnalysisStatus = (state: RootState) =>
  state.quizCheatChecking.pageViewAnalysisStatus;
export const pageViewAnalysisError = (state: RootState) =>
  state.quizCheatChecking.pageViewAnalysisError;
export const pageViewAnalysis = (state: RootState) =>
  state.quizCheatChecking.pageViewAnalysis;
export const studentInfoStatus = (state: RootState) =>
  state.quizCheatChecking.studentInfoStatus;
export const studentInfoError = (state: RootState) =>
  state.quizCheatChecking.studentInfoError;
export const studentInfo = (state: RootState) =>
  state.quizCheatChecking.studentInfo;
export const selectedStudents = (state: RootState) =>
  state.quizCheatChecking.selectedStudents;

export const {
  updateAnswerStats,
  cleanAnswerStats,
  updateAnswerStatsError,
  cleanAnswerAnalysisMap,
  updateAnswerAnalysisMap,
  updateAnswerAnalysisMapStatus,
  updateAnswerAnalysisMapError,
  cleanPageViewAnalysis,
  updatePageViewAnalysis,
  updatePageViewDetails,
  updateSubquestionAttemptDetails,
  updatePageViewAnalysisStatus,
  updatePageViewAnalysisError,
  cleanStudentInfo,
  updateStudentBasicInfo,
  updateStudentC4BasicInfo,
  updateStudentQuizStats,
  updateStudentInfoStatus,
  updateStudentInfoError,
  updateSelectedStudents,
} = quizCheatCheckingSlice.actions;

export default quizCheatCheckingSlice.reducer;
